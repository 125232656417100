// import React, { useState } from "react";
// import { Grid, TextField } from "@mui/material";
// import Button from "react-bootstrap/Button";
// import swal from "sweetalert";
// import { Table } from "react-bootstrap";

// export const HomeCarousel = () => {
//   const [fileUpload, setFileUpload] = useState(null);

//   const handleFileChange = (e) => {
//     if (e.target.files) {
//       setFileUpload(e.target.files[0]);
//     }
//   };

//   return (
//     <div>
//       <h2 className="organisation-head">Home Carousel</h2>
//       <form noValidate>
//         <Grid container spacing={3} style={{ marginTop: 10 }}>
//           <Grid item xs={12} sm={6} lg={4}>
//             <p id="labels">Upload Image</p>
//             <TextField name="upload-photo" type="file" onChange={handleFileChange} fullWidth />
//           </Grid>
//         </Grid>
//         <Button style={{ marginTop: 60 }} variant="primary" type="submit">
//           Submit
//         </Button>
//       </form>

//       <Table striped bordered hover variant="light" className="mt-5">
//         <thead>
//           <tr>
//             <th>Sl No</th>
//             <th>Image</th>
//             <th>Edit</th>
//             <th>Delete</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>1</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/KLE1qvAkqfESs0pAJ9aCvp5EaLHHhu6nyDiWLxlo.jpg" alt="Carousel 1" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//           <tr>
//             <td>2</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/DZ0fGkOFcIfBXJKGLXZ3xq9JLEPWG2Onre7qqLKr.jpg" alt="Carousel 2" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//           <tr>
//             <td>3</td>
//             <td>
//               <img src="https://dev.erckerala.org/api/storage/banner/jHzvyycK0jJD75fQz97qkV0N1YZ9zlcYOKgmCLQ8.jpg" alt="Carousel 3" width="100" />
//             </td>
//             <td>
//               <button className="tablebutton">Edit</button>
//             </td>
//             <td>
//               <button className="tablebutton">Delete</button>
//             </td>
//           </tr>
//         </tbody>
//       </Table>
//     </div>
//   );
// };
import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";

export const HomeCarousel = () => {
  const [fileUpload, setFileUpload] = useState(null);
  const [carouselId, setCarouselId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null); // Stores the image preview

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setFileUpload(file);
      setPreviewImage(URL.createObjectURL(file)); // Generate image preview
    }
  };

  const handleEdit = (itemId, imageUrl) => {
    setCarouselId(itemId);
    setIsEditing(true);
    setPreviewImage(imageUrl); // Show the selected image in preview
  };

  return (
    <div>
      <h2 className="organisation-head">Home Carousel</h2>
      <form noValidate>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload Image</p>

          

            {/* File Upload */}
            <TextField name="upload-photo" type="file" onChange={handleFileChange} fullWidth />
              {/* Image Preview */}
              {previewImage && (
              <div style={{ marginBottom: 10 }}>
                <img
                  src={previewImage}
                  alt="Preview"
                  width="100"
                  height="80"
                  style={{ borderRadius: 5, border: "1px solid #ccc" ,marginTop:'10px'}}
                />
              </div>
            )}
          </Grid>
        </Grid>

        <Button style={{ marginTop: 60 }} variant="primary" type="submit">
          {isEditing ? "Update" : "Submit"}
        </Button>
      </form>

      <Table striped bordered hover variant="light" className="mt-5">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Image</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              <img src="https://dev.erckerala.org/api/storage/banner/KLE1qvAkqfESs0pAJ9aCvp5EaLHHhu6nyDiWLxlo.jpg" alt="Carousel 1" width="100" />
            </td>
            <td>
              <button className="tablebutton" onClick={() => handleEdit(1, "https://dev.erckerala.org/api/storage/banner/KLE1qvAkqfESs0pAJ9aCvp5EaLHHhu6nyDiWLxlo.jpg")}>Edit</button>
            </td>
            <td>
              <button className="tablebutton">Delete</button>
            </td>
          </tr>
          <tr>
            <td>2</td>
            <td>
              <img src="https://dev.erckerala.org/api/storage/banner/DZ0fGkOFcIfBXJKGLXZ3xq9JLEPWG2Onre7qqLKr.jpg" alt="Carousel 2" width="100" />
            </td>
            <td>
              <button className="tablebutton" onClick={() => handleEdit(2, "https://dev.erckerala.org/api/storage/banner/DZ0fGkOFcIfBXJKGLXZ3xq9JLEPWG2Onre7qqLKr.jpg")}>Edit</button>
            </td>
            <td>
              <button className="tablebutton">Delete</button>
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              <img src="https://dev.erckerala.org/api/storage/banner/jHzvyycK0jJD75fQz97qkV0N1YZ9zlcYOKgmCLQ8.jpg" alt="Carousel 3" width="100" />
            </td>
            <td>
              <button className="tablebutton" onClick={() => handleEdit(3, "https://dev.erckerala.org/api/storage/banner/jHzvyycK0jJD75fQz97qkV0N1YZ9zlcYOKgmCLQ8.jpg")}>Edit</button>
            </td>
            <td>
              <button className="tablebutton">Delete</button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
