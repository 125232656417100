// import React, { useState } from "react";
// import { Table, Button, Form, Container, Row, Col } from "react-bootstrap";

// export const Ombudsman = () => {
//   const [ombudsmanData, setOmbudsmanData] = useState([
//     {
//       id: 1,
//       name: "Shri . A. Chandrakumaran Nair",
//       description:
//         "Electricity Ombudsman is a statutory authority appointed or designated by the State Commission. Any consumer aggrieved by non-redressal of the grievance under CGRF, may approach the Electricity Ombudsman. The Ombudsman has been entrusted with the task of settling the grievance of consumers as per the provisions of KSERC (Consumer Grievance Redressal and Electricity Ombudsman) Regulations 2005.",
//       address:
//         "The State Electricity Ombudsman, D.H Road, Offshore Road Junction, Near Gandhi Square, Ernakulam, Kerala - 682 016",
//       phone: "0484-2346488",
//       mobile: "+91 8714356488",
//       email: "ombudsman.electricity@gmail.com",
//       website: "www.keralaeo.org",
//       latestOrderLink: "#",
//       image: "https://www.erckerala.org/static/media/ombuds-img.5ef901cd1db2553de483.jpeg"
//     },
//   ]);

//   const [formData, setFormData] = useState({
//     name: "",
//     description: "",
//     address: "",
//     phone: "",
//     mobile: "",
//     email: "",
//     website: "",
//     latestOrderLink: "",
//      image: ""
//   });

//   const [editingId, setEditingId] = useState(null);
//   const handleChange = (e) => {
//     if (e.target.name === "image") {
//       setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) });
//     } else {
//       setFormData({ ...formData, [e.target.name]: e.target.value });
//     }
//   };



//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (editingId) {
//       setOmbudsmanData(
//         ombudsmanData.map((item) => (item.id === editingId ? { ...item, ...formData } : item))
//       );
//       setEditingId(null);
//     } else {
//       setOmbudsmanData([...ombudsmanData, { id: Date.now(), ...formData }]);
//     }
//     setFormData({
//       name: "",
//       description: "",
//       address: "",
//       phone: "",
//       mobile: "",
//       email: "",
//       website: "",
//       latestOrderLink: "",
//          image: ""
//     });
//   };

//   const handleEdit = (id) => {
//     const recordToEdit = ombudsmanData.find((item) => item.id === id);
//     setFormData(recordToEdit);
//     setEditingId(id);
//   };

//   const handleDelete = (id) => {
//     setOmbudsmanData(ombudsmanData.filter((item) => item.id !== id));
//   };

//   return (
//    <div>
//       <Container>
//         <h2 className="organisation-head">Ombudsman</h2>
//         <Form onSubmit={handleSubmit}>
//           <Row>
//   <Col md={6}>
//   <Form.Group controlId="formImage">
//             <Form.Label>Image</Form.Label>
//             <Form.Control type="file" name="image" onChange={handleChange} />
//           </Form.Group>
//   </Col>

  
//             <Col md={6}>
//               <Form.Group controlId="formName">
//                 <Form.Label>Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>
//             </Col>
//        <Col md={6}>
//               <Form.Group controlId="formDescription">
//               <Form.Label>Description</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 name="description"
//                 value={formData.description}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//        </Col>
//        <Col md={6}>
//             <Form.Group controlId="formAddress">
//               <Form.Label>Address</Form.Label>
//               <Form.Control
//                 as="textarea"
//                 rows={3}
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//             <Col md={6}>
//               <Form.Group controlId="formPhone">
//                 <Form.Label>Phone</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="phone"
//                   value={formData.phone}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
        
//             <Col md={6}>
//               <Form.Group controlId="formMobile">
//                 <Form.Label>Mobile</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="mobile"
//                   value={formData.mobile}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formEmail">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
        
//             <Col md={6}>
//               <Form.Group controlId="formWebsite">
//                 <Form.Label>Website</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="website"
//                   value={formData.website}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
//             <Col md={6}>
//               <Form.Group controlId="formLatestOrderLink">
//                 <Form.Label>Latest Order Link</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="latestOrderLink"
//                   value={formData.latestOrderLink}
//                   onChange={handleChange}
//                 />
//               </Form.Group>
//             </Col>
         
         
//           </Row>
//           <Button variant="primary" type="submit" className="mt-3">
//             {editingId ? "Update Ombudsman" : "Add Ombudsman"}
//           </Button>
//         </Form>
//         </Container>
        
//        <Container fluid>
//           <Table striped bordered hover variant="light" className="mt-4">
//             <thead>
//               <tr>
//               <th>Image</th>
//                 <th>Name</th>
//                 <th>Description</th>
//                 <th>Address</th>
//                 <th>Phone</th>
//                 <th>Mobile</th>
//                 <th>Email</th>
//                 <th>Website</th>
//                 <th>Latest Order</th>
//                 <th>Edit</th>
//                 <th>Delete</th>
//               </tr>
//             </thead>
//             <tbody>
//               {ombudsmanData.map((ombudsman) => (
//                 <tr key={ombudsman.id}>
//                    <td>
//                     {ombudsman.image && <img src={ombudsman.image} alt="Ombudsman" width="50" />}
//                   </td>
//                   <td>{ombudsman.name}</td>
//                   <td>{ombudsman.description}</td>
//                   <td>{ombudsman.address}</td>
//                   <td>{ombudsman.phone}</td>
//                   <td>{ombudsman.mobile}</td>
//                   <td>{ombudsman.email}</td>
//                   <td>{ombudsman.website}</td>
//                   <td>
//                     <a href={ombudsman.latestOrderLink} target="_blank" rel="noopener noreferrer">
//                       View Order
//                     </a>
//                   </td>
//                   <td>
//                     <button className="tablebutton"  onClick={() => handleEdit(ombudsman.id)}>
//                       Edit
//                     </button>
//                   </td>
//                   <td>
//                     <button className="tablebutton"  onClick={() => handleDelete(ombudsman.id)}>
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//        </Container>
//    </div>
    
//   );
// };
import React, { useState } from "react";
import { Table, Button, Form, Container, Row, Col } from "react-bootstrap";

export const Ombudsman = () => {
  const [ombudsmanData, setOmbudsmanData] = useState([
    {
      id: 1,
      name: "Shri . A. Chandrakumaran Nair",
      description:
        "Electricity Ombudsman is a statutory authority appointed or designated by the State Commission. Any consumer aggrieved by non-redressal of the grievance under CGRF, may approach the Electricity Ombudsman. The Ombudsman has been entrusted with the task of settling the grievance of consumers as per the provisions of KSERC (Consumer Grievance Redressal and Electricity Ombudsman) Regulations 2005.",
      address:
        "The State Electricity Ombudsman, D.H Road, Offshore Road Junction, Near Gandhi Square, Ernakulam, Kerala - 682 016",
      phone: "0484-2346488",
      mobile: "+91 8714356488",
      email: "ombudsman.electricity@gmail.com",
      website: "www.keralaeo.org",
      latestOrderLink: "#",
      image: "https://www.erckerala.org/static/media/ombuds-img.5ef901cd1db2553de483.jpeg"
    },
  ]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    address: "",
    phone: "",
    mobile: "",
    email: "",
    website: "",
    latestOrderLink: "",
    image: ""
  });

  const [editingId, setEditingId] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setFormData({ ...formData, image: URL.createObjectURL(e.target.files[0]) });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editingId) {
      setOmbudsmanData(
        ombudsmanData.map((item) => (item.id === editingId ? { ...item, ...formData } : item))
      );
      setEditingId(null);
    } else {
      setOmbudsmanData([...ombudsmanData, { id: Date.now(), ...formData }]);
    }
    setFormData({
      name: "",
      description: "",
      address: "",
      phone: "",
      mobile: "",
      email: "",
      website: "",
      latestOrderLink: "",
      image: ""
    });
  };

  const handleEdit = (id) => {
    const recordToEdit = ombudsmanData.find((item) => item.id === id);
    setFormData(recordToEdit);
    setEditingId(id);
  };

  const handleDelete = (id) => {
    setOmbudsmanData(ombudsmanData.filter((item) => item.id !== id));
  };

  return (
    <div>
      <Container>
        <h2 className="organisation-head">Ombudsman</h2>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formImage">
                <Form.Label>Image</Form.Label>
                <Form.Control type="file" name="image" onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formMobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWebsite">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formLatestOrderLink">
                <Form.Label>Latest Order Link</Form.Label>
                <Form.Control
                  type="text"
                  name="latestOrderLink"
                  value={formData.latestOrderLink}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit" className="mt-3">
            {editingId ? "Update Ombudsman" : "Add Ombudsman"}
          </Button>
        </Form>
      </Container>

      <Container fluid>
      <Table striped bordered hover variant="light" className="mt-4" style={{width:'auto'}}>
  <thead>
    <tr>
      <th>Image</th>
      <th>Name</th>
      <th style={{width:'30%'}}>Description</th>
      <th style={{width:'30%'}}>Address</th>
      <th>Phone</th>
      <th>Mobile</th>
      <th style={{width:'30%'}}>Email</th>
      <th>Website</th>
      <th>Latest Order</th>
      <th>Edit</th>
      <th>Delete</th>
    </tr>
  </thead>
  <tbody>
    {ombudsmanData.map((ombudsman) => (
      <tr key={ombudsman.id}>
        <td>
          {ombudsman.image && <img src={ombudsman.image} alt="Ombudsman" width="50" />}
        </td>
        <td>{ombudsman.name}</td>
        <td style={{width:'30%',textAlign:'justify'}}>
          {ombudsman.description}
        </td>
        <td style={{width:'30%',textAlign:'justify'}}>
          {ombudsman.address}
        </td>
        <td>{ombudsman.phone}</td>
        <td>{ombudsman.mobile}</td>
        <td style={{ whiteSpace: "normal", wordBreak: "break-word",width:'30%' }}>
          {ombudsman.email}
        </td>
        <td>{ombudsman.website}</td>
        <td>
          <a href={ombudsman.latestOrderLink} target="_blank" rel="noopener noreferrer">
            View Order
          </a>
        </td>
        <td>
          <button className="tablebutton" onClick={() => handleEdit(ombudsman.id)}>
            Edit
          </button>
        </td>
        <td>
          <button className="tablebutton" onClick={() => handleDelete(ombudsman.id)}>
            Delete
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>


      </Container>
    </div>
  );
};